import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import ProjectHero from "../components/ProjectHero";
import "./Article.css"
import ProjectCard from "../components/ProjectCard";


export default props => {
  return ( 
    <Layout>
        <ProjectHero
          title="Fastback Studios"
          intro="Fastback Studios is a Seattle-based professional recording studio. My team redesigned the studio's official website based on their top business goals."
          client="Fastback Studios"
          team="Team of 5, 10 weeks in 2018"
          myrole="User interview, Competitive testing, Information design, Visual design"
          />

      <div className="FullImage">
        <Img fluid={props.data.fbmockup.childImageSharp.fluid} />
      </div>





    <div className="ArticleSection">
      <h1 style={{color:"#CF2E33"}}>Define —―</h1>
      <div className="ArticleBody">
        <h2>What does success look like?</h2>
        <p>First, we sat together with Jason -- the owner of Fastback Studios -- to learn about the business goals of the studio. We helped Jason identify things that can be achieved through a website, and set up success metrics for those goals. </p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.bizGoals.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <h2>Who's the target user?</h2>
        <p>Our next step was to find out the target user group -- people who impact the business goals the most. To do that, we leveraged the two-step ad-hoc persona development method, as shown below. </p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.adhocPersona.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <p><br />Sythesizing the "I want / I need" statements, we identified three major client groups -- professional artists, band managers, and "mid-life crisis" amateur musicians.</p>
      </div>
    </div>

      <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.persona.childImageSharp.fluid} /></div>
      </div>

      <div className="ArticleSection">
      <div className="ArticleBody">
        <p>We weighted each user group by the impact they would have on the three business goals. It turned out that <em>Aaron Artist</em> - the professional musician - was the most decisive user group.</p>
      </div>
    </div>

    <div className="ArticleSection">
      <div className="ArticleBody">
        <p><br /></p>
        <h2>Insight: genre match and producer's skills are the missing info</h2>
        <p>As soon as identifying the target users, we conducted surveys, interviews, and competitive testing with a total of 17 professional musicians to understand their decision-making process in booking recording studios. Alongside the basic studio info such as hourly rates, gears, studio space, etc., we found out two factors that Aaron would specifically look for, yet largely under-addressed in most studio websites including Fastback Studios':</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.insight.childImageSharp.fluid} /></div>
    </div>

    <div className="ArticleSection">
      <h1 style={{color:"#CF2E33"}}>Ideate —―</h1>
      <div className="ArticleBody">
        <h2>Addressing user questions in a natural flow</h2>
        <p>Based on our research, the new website should help answer three key user questions:
        <br /><br />Q1 - Are you experienced with my genre?
        <br />Q2 - How good are you at mixing and producing?
        <br />Q3 - How do I book your service?
        <br /><br />We addressed those questions through wireframe design.
        </p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="FullImage"><Img fluid={props.data.final1.childImageSharp.fluid} /></div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="FullImage"><Img fluid={props.data.final2.childImageSharp.fluid} /></div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="FullImage"><Img fluid={props.data.final3.childImageSharp.fluid} /></div>
    </div>    

    <div className="ArticleSection">
      <h1 style={{color:"#CF2E33"}}>Visual design —―</h1>
      <div className="ArticleBody">
        <h2>Finding the right visual language</h2>
        <p>Finally, we asked Jason three questions to understand Fastback Studios’ brand tenets:
        <br /><br />Q1 - How you want people to interpret Fastback Studios?
        <br />Q2 - What makes Fastback Studios unique?
        <br />Q3 - In an ideal future state, what 3 words would you use to describe Fastback Studios?
        <br /><br />From there, we recognized three brand tenets and translated them into design guidelines.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.branding.childImageSharp.fluid} /></div>
    </div>    

    <div className="ArticleSection">
      <div className="ArticleBody">
        <p><br />I explored several visual concepts and created a style guide for the website.</p>
      </div>
    </div>

    <div className="BackgroundWrap" color="white">
            <div className="RegImage"><Img fluid={props.data.styleGuide.childImageSharp.fluid} /></div>
    </div>  


    <div className="ArticleSection">
      <div className="ArticleBody">
        <p><br /></p>
        <h2>Final design</h2>
      </div>
    </div>

    <div className="BackgroundWrap" color="black">
            <p><br /></p>
            <div className="FullImage"><Img fluid={props.data.home.childImageSharp.fluid} /></div>
    </div>    

    <div className="BackgroundWrap" color="black">
            <div className="FullImage"><Img fluid={props.data.about.childImageSharp.fluid} /></div>
    </div>    

    <div className="BackgroundWrap" color="black">
            <div className="FullImage"><Img fluid={props.data.work.childImageSharp.fluid} /></div>
    </div>  

    <div className="BackgroundWrap" color="black">
            <div className="FullImage"><Img fluid={props.data.price.childImageSharp.fluid} /></div>
    </div>  

    <div className="ArticleSection">
      <div className="Next">
        <h6 style={{color:"#8E8E8E"}}>Next up</h6>
        <ProjectCard 
            link="/gaido-health/"
            title="Gaido Health"
            tagline="Balance user needs and data needs in an early-stage AI system"
            tag="Mobile App • Internship Project"
            // image={require('../images/test.png')}
            background={require('../images/cover/bcgdv.jpg')}
            />
      </div>
    </div>  
    

    </Layout>
 )
}

export const query = graphql`
  query {
    mockup: file(relativePath: { eq: "start/mock-up.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    bizGoals: file(relativePath: { eq: "fastback-studios/biz-goals.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    adhocPersona: file(relativePath: { eq: "fastback-studios/ad-hoc-persona.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    persona: file(relativePath: { eq: "fastback-studios/persona.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    insight: file(relativePath: { eq: "fastback-studios/research-insight.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    contentPlanning: file(relativePath: { eq: "fastback-studios/content-planning.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    final1: file(relativePath: { eq: "fastback-studios/final-1.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    final2: file(relativePath: { eq: "fastback-studios/final-2.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    final3: file(relativePath: { eq: "fastback-studios/final-3.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    branding: file(relativePath: { eq: "fastback-studios/branding.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    styleGuide: file(relativePath: { eq: "fastback-studios/style-guide.png" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    home: file(relativePath: { eq: "fastback-studios/hifi-home.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    about: file(relativePath: { eq: "fastback-studios/hifi-about.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    work: file(relativePath: { eq: "fastback-studios/hifi-work.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    price: file(relativePath: { eq: "fastback-studios/hifi-price.jpg" }){
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    } 

    fbmockup: file(relativePath: { eq: "fastback-studios/mockup.png" }){
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    } 


  }
`
